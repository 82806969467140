@tailwind base;
@tailwind components;
@tailwind utilities;

.video-background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.content {
  position: relative;
  height: 100%;
}

.frame-image {
  width: 100%;
  height: 100%;
}

.frame-image {
  width: 100%;
  height: 100%;
}

.video-container {
  display: flex;
  position: absolute;
  margin: auto;
  align-items: center;
  justify-content: center;
  top: 2rem;
  width: 80%;
  height: 92%;
  background-color: black;
  left: 10%;
  border-radius: 2.5rem;
}

.video-content {
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 2.5rem;
}
